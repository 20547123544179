<template>
    <div ref="search-container" style="height: 100%">
        <div class="detail-header">
            <!-- <div class="col-md-12 d-flex align-items-center justify-content-between"> -->
            <div class="detail-title" ref="search-header">
                <!-- <span class="title mb-0" for="propCode">{{ `관제점 ${type !== 'global' ? '선택' : '조회'}` }}</span> -->
                <div class="title">
                    <div class="input-group">
                        <div class="btn-group" id="propCode">
                            <button type="button" class="btn btn-md "
                                :class="{ 'btn-primary': searchType === 'point', 'btn-light': searchType !== 'point' }"
                                @click="selectSearchType('point')">
                                관제점 정보로 찾기
                            </button>
                            <button type="button" class="btn btn-md "
                                :class="{ 'btn-primary': searchType === 'device', 'btn-light': searchType !== 'device' }"
                                @click="selectSearchType('device')">
                                연동장치로 찾기
                            </button>
                            <button type="button" class="btn btn-md "
                                :class="{ 'btn-primary': searchType === 'equip', 'btn-light': searchType !== 'equip' }"
                                @click="selectSearchType('equip')">
                                설비 정보로 찾기
                            </button>
                        </div>

                    </div>
                </div>
                <div class="title">
                    <span class="setting-icon me-3"><i class="fas fa-list" @click="openTableViewSelect"></i></span>
                    <span class="setting-icon" @click="closeSearchPoint"><i class="fas fa-times"></i></span>
                </div>
            </div>

            <div class="detail-title" ref="search-select">
                <div class="title" style="width:100%">

                    <div class="title flex-grow-1" v-if="searchType == 'device'">
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond1" class="input-group-text">장치 유형</span>
                            </div>
                            <select v-model="selectOptions.firstOption" id="baseCond1" class="form-control "
                                @change="selectFirstOption">
                                <option :value="null">선택</option>
                                <option v-for="(meterType) in searchList" :key="meterType.meterTypeCode"
                                    :value="meterType.meterTypeCode">
                                    {{ meterType.meterTypeName ?? '-' }}
                                </option>
                            </select>
                        </div>

                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond2" class="input-group-text">연동 장치</span>
                            </div>
                            <select v-model="selectOptions.secondOption" id="baseCond2" class="form-control "
                                @change="selectSecondOption">
                                <option :value="null">선택</option>
                                <option v-for="(device) in secondOptList" :key="device.parentFacilityIdx"
                                    :value="device.parentFacilityIdx">
                                    {{ device.parentFacilityName }}
                                </option>
                            </select>
                        </div>

                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond3" class="input-group-text">서브 모듈</span>
                            </div>
                            <!-- <select v-model="selectOptions.thirdOption" id="baseCond3" class="form-control "
                                @change="searchPointList"> -->
                            <select v-model="selectOptions.thirdOption" id="baseCond3" class="form-control"
                                @change="handleSearch">
                                <option :value="null">선택</option>
                                <option v-for="(facility) in thirdOptList" :key="facility.facilityIdx"
                                    :value="facility.facilityIdx">
                                    {{ facility.facilityName }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="title flex-grow-1" v-if="searchType == 'point'">
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond1" class="input-group-text">관제점 유형</span>
                            </div>
                            <select v-model="selectOptions.firstOption" id="baseCond1" class="form-control "
                                @change="selectFirstOption">
                                <option :value="null">선택</option>
                                <option v-for="(virtual) in searchList" :key="virtual.isVirtual"
                                    :value="virtual.isVirtual">
                                    {{ virtual.isVirtualName }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond2" class="input-group-text">관제점 그룹</span>
                            </div>
                            <select v-model="selectOptions.secondOption" id="baseCond2" class="form-control"
                                @change="selectSecondOption">
                                <option :value="null">관제점 그룹</option>
                                <option v-for="(group) in secondOptList" :key="group.ptGroup" :value="group.ptGroup">
                                    {{ group.ptGroupName }}
                                </option>

                            </select>
                        </div>
                        <div class="input-group mr-2">
                            <!-- <div class="input-group-prepend">
                                <span for="baseCond3" class="input-group-text">단위 유형</span>
                            </div>
                            <select v-model="selectOptions.thirdOption" id="baseCond3" class="form-control"
                                @change="handleSearch">
                                <option :value="null">선택</option>
                                <option v-for="(code) in thirdOptList" :key="code.unitLargeCode"
                                    :value="code.unitLargeCode">
                                    {{ code.unitLargeCodeName }}
                                </option>
                            </select> -->
                            <div class="input-group-prepend">
                                <span for="baseCond3" class="input-group-text">사용 용도</span>
                            </div>
                            <select v-model="selectOptions.thirdOption" id="baseCond3" class="form-control"
                                @change="handleSearch">
                                <option :value="null">선택</option>
                                <option v-for="(code, index) in usageList" :key="index" :value="code.value">
                                    {{ code.text }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="title flex-grow-1" v-if="searchType == 'equip'">
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond1" class="input-group-text">설비 유형</span>
                            </div>
                            <select v-model="selectOptions.firstOption" id="baseCond1" class="form-control"
                                @change="selectFirstOption">
                                <option :value="null">선택</option>
                                <option v-for="(type) in searchList" :key="type.equipType" :value="type.equipType">
                                    {{ type.equipTypeName }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond2" class="input-group-text">연동 장치</span>
                            </div>
                            <select v-model="selectOptions.secondOption" id="baseCond2" class="form-control"
                                @change="selectSecondOption">
                                <option :value="null">연동 장치</option>
                                <option v-for="(type) in secondOptList" :key="type.deviceIdx" :value="type.deviceIdx">
                                    {{ type.deviceName }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group mr-2">
                            <div class="intput-group-prepend">
                                <label for="baseCond3" class="input-group-text">설비</label>
                            </div>
                            <!-- <select v-model="selectOptions.thirdOption" id="thirdOption" class="form-control"
                                @change="searchPointList"> -->
                            <select v-model="selectOptions.thirdOption" id="thirdOption" class="form-control"
                                @change="handleSearch">
                                <option :value="null">선택</option>
                                <option v-for="(equip) in thirdOptList" :key="equip.equipIdx" :value="equip.equipIdx">
                                    {{ equip.equipName }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- 공통 : 버튼 컨트롤  -->
                    <div class="title btn-group">
                        <button type="button" class="btn btn-secondary" @click="toggleSearchMode">확장 검색</button>
                        <button type="button" class="btn btn-primary " @click="handleSearch">조회</button>
                        <button v-show="type !== 'global'" type="button" class="btn btn-warning"
                            @click="selectPointInfo">선택</button>
                    </div>
                </div>
            </div>

            <!-- 확장 검색 영역 -->
            <b-collapse v-model="openOptional" id="OptionalSelect" style="width:100%">
                <div class="detail-title" ref="search-select-optional">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">확장 검색</span>
                        </div>
                        <select v-model="optional.ptTypeCode" id="ptTypeCode" class="form-control"
                            @change="applyFilter">
                            <option :value="null">집계 유형</option>
                            <option v-for="(code, index) in ptTypeList" :key="index" :value="code.value">
                                {{ code.text }}
                            </option>
                        </select>
                        <select v-model="optional.unitSmallCode" id="unitSmallCode" class="form-control"
                            @change="applyFilter">
                            <option :value="null">단위</option>
                            <option v-for="(code, index) in unitList" :key="index" :value="code.value">
                                {{ code.text }}
                            </option>
                        </select>
                        <select v-model="optional.energySourceCode" id="energySourceCode" class="form-control"
                            @change="applyFilter">
                            <option :value="null">사용 에너지원</option>
                            <option v-for="(code, index) in energyList" :key="index" :value="code.value">
                                {{ code.text }}
                            </option>
                        </select>
                        <select v-model="optional.itemUseageCode" id="itemUseageCode" class="form-control"
                            @change="applyFilter">
                            <option :value="null">사용 용도</option>
                            <option v-for="(code, index) in usageList" :key="index" :value="code.value">
                                {{ code.text }}
                            </option>
                        </select>
                        <select v-model="optional.applyZoneIdx" id="applyZoneIdx" class="form-control"
                            @change="applyFilter">
                            <option :value="null">적용 구역</option>
                            <option v-for="(code, index) in zoneList" :key="index" :value="code.value">
                                {{ code.text }}
                            </option>
                        </select>
                        <input v-model="optional.filterText" type="text" class="form-control" id="filterText"
                            @keydown.enter="applyFilter" :placeholder="'검색 텍스트 입력'">
                    </div>
                </div>
            </b-collapse>
        </div>
        <div v-if="searchList" class="form-row flex-grow-1" :style="{ height: listHeight }">
            <div v-if="!pointList && !isLoading" class="col-md-12 detail-placeholder">

                <!-- 관제점 일반 현황 정보 삽입 -->
                <point-overview />

            </div>
            <div v-else-if="!pointList && isLoading" class="col-md-12 detail-placeholder">

                <!-- 데이터 로딩중 -->
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
            <div v-else class="col-md-12" style="height: 100%;">

                <!-- <vue-good-table ref="list-table" style="margin-top: 0.5rem; height:100%; width:100%;" compactMode
                    fixed-header :columns="vgtColumns" :rows="pointList" :row-style-class="rowStyleClassFn"
                    :select-options="{ enabled: true, disableSelectInfo: true, selectOnCheckboxOnly: true }"
                    :max-height="openOptional ? optionTableHeight : tableMaxHeight" :selected-rows="[selectRow]"
                    @on-row-click="handleRowClick">
                    <div slot="emptystate" style="text-align: center;">
                        {{ $t('표시할 데이터가 없습니다.') }}
                    </div>
                    <template slot="table-row" slot-scope="props">
                        <div v-for="column in vgtColumns" :key="column.field">
                            <template v-if="props.column.field === column.field">
                                <template>
                                    <div>
                                        <div v-html="formatValue(props.column.field, props.row)"></div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </template>
                </vue-good-table> -->
                <vue-good-table ref="list-table" 
                style="margin-top: 0.5rem; height:100%; width:100%;" 
                compactMode
                fixed-header 
                :columns="vgtColumns" 
                :rows="pointList" 
                :row-style-class="rowStyleClassFn"
                :select-options="{ enabled: true, disableSelectInfo: true, selectOnCheckboxOnly: true }"
                :max-height="openOptional ? optionTableHeight : tableMaxHeight" 
                @on-row-click="handleRowClick"
                @on-selected-rows-change="selectionChanged"
                :pagination-options="paginationOptions"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                :total-rows="countTotalData"
                >
                    <div slot="emptystate" style="text-align: center;">
                        {{ $t('표시할 데이터가 없습니다.') }}
                    </div>
                    <template slot="table-row" slot-scope="props">
                        <div v-for="column in vgtColumns" :key="column.field">
                            <template v-if="props.column.field === column.field">
                                <template>
                                    <div>
                                        <div v-html="formatValue(props.column.field, props.row)"></div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </div>

        <SetPointListOptionsModal ref="setPointListOptionsModal" @confirm="handlePointListOptions" />

        <b-modal v-if="clickedRow" ref="pointDetailModal" class="custom-pt-detail-modal" hide-footer centered size="lg">
            <template #modal-header>
                <h5>관제점 상세</h5>
            </template>
            <point-detail ref="point-detail" :title="''" :ptIdx="clickedRow.ptIdx" :noWatch="true" :customHeight="'65vh'"
                @data-updated="handleDataUpdated" @closed="handleClosed" />
        </b-modal>
    </div>
</template>

<script>
import backEndApi from '@src/api/backEndApi';
import PointOverview from '@src/views/new/point-management/point-mgmt/PointOverview.vue'
import SetPointListOptionsModal from '@src/views/new/point-management/point-mgmt/modal/SetPointListOptionsModal.vue';
import PointDetail from '@src/views/new/point-management/point-mgmt/PointDetail.vue'

export default {
    props: {
        data: { type: Object },
        // transCodes: { type: Array },
        tableMaxHeight: { type: String, default: '' },
        optionTableHeight: { type: String, default: '' },
        type: { type: String, default: '' },
        mode: { type: String, default: 'single' },
    },
    components: {
        PointOverview,
        SetPointListOptionsModal,
        PointDetail,
    },
    data() {
        return {
            /** common */
            searchList: null,
            pointList: null,
            basePointList: null,
            searchType: "device",
            listHeight: null,

            /** 필수 선택 변수 */
            selectOptions: {
                firstOption: null,
                secondOption: null,
                thirdOption: null,
            },
            secondOptList: null,
            thirdOptList: null,

            /** optional select */
            optional: {
                ptTypeCode: null,
                unitSmallCode: null,
                energySourceCode: null,
                itemUseageCode: null,
                applyZoneIdx: null,
                filterText: null,
            },
            openOptional: false,

            /** 추가 옵션 select list */
            ptTypeList: null,
            unitList: null,
            energyList: null,
            usageList: null,
            zoneList: null,

            /** table */
            columns: null,
            vgtColumns: null,
            selectTableView: null,
            currentPage: 1,
            offset: 0, // 데이터를 가져올 때 사용할 offset
            limit: 10,
            countDataFromServer:0, // 프론트에서 측정한 현재까지 로드된 데이터 갯수
            countTotalData:0,
            paginationOptions: {
            enabled: true,
            mode: 'pages', // or 'records'
            perPage: 10, // 페이지당 데이터 개수
            },

            /** 기타 */
            isLoading: false,
            clickedRow: null,

            transCodes: [
                {
                    field: 'energySourceCode',
                    codes: this.$store.state.commonCodes.energySourceCode,
                },
                {
                    field: 'itemUseageCode',
                    codes: this.$store.state.commonCodes.itemUseageCode,
                },
                {
                    field: 'unitLargeCode',
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: 'unitSmallCode',
                    codes: this.$store.state.units,
                },
                {
                    field: 'applyZoneIdx',
                    codes: this.$store.state.zoneInfo,
                },
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: 'ptTypeCode',
                    codes: this.$store.state.commonCodes.ptTypeCode,
                },
                {
                    field: 'purpsUseCode',
                    codes: this.$store.state.commonCodes.purpsUseCode,
                },
            ],
        }
    },
    computed: {
        pointListOptions() {
            return this.$store.state.pointListOptions;
        }
    },
    watch: {
        searchType: {
            deep: true,
            async handler(newVal) {
                console.log("change searchType : ", newVal)
                if (newVal == 'device') await this.getFacilityClass();
                else if (newVal == 'point') await this.getPointClass();
                else await this.getEquipClass();
            }
        },
        openOptional: {
            deep: true,
            handler(newVal) {
                if (newVal === false) this.initOptionalSelect();
            }
        },
    },
    async mounted() {
        const searchOptions = this.$store.state.searchOptions.pointInfo;

        if (searchOptions.searchType) {
            console.log("searchOptions.searchType : ", searchOptions.searchType)
            this.searchType = searchOptions.searchType;

            if (this.searchType == 'device') await this.getFacilityClass();
            else if (this.searchType == 'point') await this.getPointClass();
            else await this.getEquipClass();
        }
        // 관제점 유형의 물리 관제점 값이 0 이므로 0 도 혀용되게 함.
        if (searchOptions.firstOption || searchOptions.firstOption == 0) {
            console.log("searchOptions.firstOption : ", searchOptions.firstOption)
            this.selectOptions.firstOption = searchOptions.firstOption;

            await this.selectFirstOption();
        }
        if (searchOptions.secondOption) {
            console.log("searchOptions.secondOption : ", searchOptions.secondOption)
            this.selectOptions.secondOption = searchOptions.secondOption;

            await this.selectSecondOption();
        }

        if (searchOptions.thirdOption) this.selectOptions.thirdOption = searchOptions.thirdOption;
        console.log('selectOptions:', this.selectOptions)

        if (this.selectOptions.firstOption != null) await this.searchPointList();
    },
    async created() {
        await this.getFacilityClass();
        await this.listContainerHeight();
        await this.setColumns();
        await this.initOptionalSelectList();
    },
    destroyed() {
        console.log("Search Component Destroyed!!!")
    },
    methods: {
    onPageChange(params) {
    this.currentPage=params.currentPage;
    this.offset = (this.currentPage - 1) * this.limit; // 페이지 변경 시 오프셋 계산
    this.searchPointList(); // 데이터 가져오기
    },
    onPerPageChange(params) {
    this.limit = params.currentPerPage; // 페이지당 데이터 수 변경
    this.offset = 0; // 오프셋 초기화
    this.searchPointList(); // 데이터 가져오기
    },
        selectionChanged(params) {
            if(this.type !== 'global' && this.mode == 'single') {
                if (params.selectedRows.length > 1) {
                    this.alertNoti("하나의 관제점만 선택할 수 있습니다.")
                    return;
                }
            }
        },
        toggleSearchMode() {
            this.openOptional = !this.openOptional;
        },
        // 장치 분류체계 조회
        async getFacilityClass() {
            try {
                let result = await backEndApi.points.getFacilityClass();
                if (result.status == 200) {
                    this.searchList = result.data;
                }
            } catch (e) {
                console.error(e)
            }
        },

        // 관제점정보 분류체계 조회
        async getPointClass() {
            try {
                let result = await backEndApi.points.getPointClass();
                if (result.status == 200) {
                    this.searchList = result.data;
                }
            } catch (e) {
                console.error(e)
            }
        },

        // 설비 분류체계 조회
        async getEquipClass() {
            try {
                let result = await backEndApi.points.getEquipClass();
                if (result.status == 200) {
                    this.searchList = result.data;
                }
            } catch (e) {
                console.error(e)
            }
        },

        // selectOptions 초기화
        initSelectOptions() {
            this.selectOptions = {
                firstOption: null,
                secondOption: null,
                thirdOption: null,
            }
        },

        // optional select 초기화
        initOptionalSelect() {
            this.optional = {
                ptTypeCode: null,
                unitSmallCode: null,
                energySourceCode: null,
                itemUseageCode: null,
                applyZoneIdx: null,
                filterText: null,
            }
        },

        // firstOption Select Event
        async selectFirstOption() {
            this.selectOptions.secondOption = null;
            this.selectOptions.thirdOption = null;
            this.secondOptList = null;
            this.thirdOptList = null;
            this.pointList = null;
            if (this.selectOptions.firstOption !== null || undefined) {
                if (this.searchType == 'device') {
                    let found = this.searchList.find(item => item.meterTypeCode == this.selectOptions.firstOption).parentFacilities;
                    if (found) this.secondOptList = found;
                } else if (this.searchType == 'point') {
                    console.log(this.searchList)
                    let found = this.searchList.find(item => item.isVirtual == this.selectOptions.firstOption).ptGroups;
                    if (found) this.secondOptList = found;

                    await this.handleSearch();
                } else {
                    let found = this.searchList.find(item => item.equipType == this.selectOptions.firstOption).devices;
                    if (found) this.secondOptList = found;
                }
            } else {
                this.secondOptList = null;
                this.thirdOptList = null;
            }
        },

        async handleSearch() {
            // await this.$store.commit("SET_FIRST_OPTIONS", this.selectOptions.firstOption);
            // await this.$store.commit("SET_SECOND_OPTIONS", this.selectOptions.secondOption);
            // await this.$store.commit("SET_THIRD_OPTIONS", this.selectOptions.thirdOption);

            console.log('handleSearch(). ', this.searchType, this.selectOptions.firstOption, this.selectOptions.secondOption, this.selectOptions.thirdOption);

            // 관제점 검색이 아닌 경우 세번째 주 검색조건까지 모두 선택해야 조회 가능함.
            if (this.searchType !== 'point' && this.selectOptions.thirdOption === null) {
                this.alertWarning("조건을 모두 선택하세요.")
                return;
            }

            // 조회실행시 searchOptions를 갱신해서 Store에 저장함.
            const searchOptions = this.$store.state.searchOptions;
            searchOptions.pointInfo = {
                searchType: this.searchType,
                firstOption: this.selectOptions.firstOption,
                secondOption: this.selectOptions.secondOption,
                thirdOption: this.selectOptions.thirdOption,
            };
            await this.$store.commit("SET_SEARCH_OPTIONS", searchOptions);
            console.log('SET_SEARCH_OPTIONS.', searchOptions);

            this.initOptionalSelect();

            await this.searchPointList();
        },

        // secondOption Select Event
        async selectSecondOption() {
            this.selectOptions.thirdOption = null;
            this.thirdOptList = null;
            this.pointList = null;
            if (this.selectOptions.secondOption !== null || undefined) {
                if (this.searchType == 'device') {
                    let found = this.secondOptList.find(item => item.parentFacilityIdx == this.selectOptions.secondOption).facilities;
                    if (found) this.thirdOptList = found;
                } else if (this.searchType == 'point') {
                    let found = this.secondOptList.find(item => item.ptGroup == this.selectOptions.secondOption).itemUseageCodes;
                    if (found) this.thirdOptList = found;

                    await this.handleSearch();
                } else {
                    let found = this.secondOptList.find(item => item.deviceIdx == this.selectOptions.secondOption).equips;
                    if (found) this.thirdOptList = found;
                }
            } else this.thirdOptList = null;
        },

        // 관제점 조회
        async searchPointList() {
    let searchParams = { ...this.optional };
    this.basePointList = null;

    console.log("isLoading countDataFromServer countTotal", this.isLoading, this.countDataFromServer, this.countTotalData);
    if (this.isLoading) return;

    this.isLoading = true;

    try {
        let result;
        if (this.searchType == 'device') {
            if (this.selectOptions.thirdOption) {
                searchParams.facilityIdx = this.selectOptions.thirdOption;
                result = await backEndApi.points.getPointInfoByFacility(searchParams, this.offset, this.limit);
            }
        } else if (this.searchType == 'point') {
            searchParams.isVirtual = this.selectOptions.firstOption;
            searchParams.ptGroup = this.selectOptions.secondOption;
            searchParams.itemUseageCode = this.selectOptions.thirdOption;
            result = await backEndApi.points.getPointInfoByGroup(searchParams, this.offset, this.limit);
            
        } else {
            if (this.selectOptions.thirdOption) {
                searchParams.equipIdx = this.selectOptions.thirdOption;
                result = await backEndApi.points.getPointInfoByEquip(searchParams, this.offset, this.limit);
            }
        }

        if (result && result.status == 200) {
            // if (this.offset === 0) {
            //     this.pointList = result.data.data; // 초기 페칭 시 기존 데이터 덮어쓰기
            // } else {
            //     this.pointList = [...this.pointList, ...result.data.data]; // 페이징 시 기존 데이터에 추가
            // }
            this.pointList = result.data.data;
            this.countTotalData = result.data.count; // 총 데이터 개수 업데이트
            // this.offset += this.limit; // 다음 페이지를 위해 offset 증가
            this.basePointList = [...this.pointList];
            await this.filterOptionalSelectList(this.pointList);
        }
    } catch (e) {
        console.error(e);
    } finally {
        this.isLoading = false;
    }
},
//기존코드
        // async searchPointList() {
        //     let searchParams = { ...this.optional };
        //     this.basePointList = null;
        //     this.isLoading = true;
        //     // this.initOptionalSelect();

        //     if (this.searchType == 'device') {
        //         try {
        //             if (this.selectOptions.thirdOption) {
        //                 searchParams.facilityIdx = this.selectOptions.thirdOption;
        //                 console.log("searchType device searchParams : ", searchParams);
        //                 let result = await backEndApi.points.getPointInfoByFacility(searchParams);
                        
        //                 if (result.status == 200) {
        //                     // if (result.data.length > 0) this.pointList = result.data;
        //                     // else this.alertWarning("검색 조건과 일치하는 내용이 없습니다. 조회 조건을 확인해주세요.")
        //                     this.pointList = result.data.data;
        //                     this.isLoading = false;
        //                     this.basePointList = [...this.pointList]
        //                     console.log("searchType device pointList : ", this.pointList);
        //                     console.log("searchType device basePointList : ", this.basePointList);
        //                     await this.filterOptionalSelectList(this.pointList);
        //                 }
        //             } else return;
        //         } catch (e) {
        //             console.error(e);
        //         }
        //     } else if (this.searchType == 'point') {
        //         try {
        //             searchParams.isVirtual = this.selectOptions.firstOption;
        //             searchParams.ptGroup = this.selectOptions.secondOption;
        //             searchParams.itemUseageCode = this.selectOptions.thirdOption;
        //             console.log("searchType point searchParams : ", searchParams);

        //             let result = await backEndApi.points.getPointInfoByGroup(searchParams);
                  
        //             if (result.status == 200) {
        //                 // if (result.data.length > 0) this.pointList = result.data;
        //                 // this.alertWarning("검색 조건과 일치하는 내용이 없습니다. 조회 조건을 확인해주세요.")
        //                 this.pointList = result.data.data;
        //                 this.isLoading = false;
        //                 this.basePointList = [...this.pointList]
        //                 console.log("searchType point pointList : ", this.pointList);
        //                 console.log("searchType device basePointList : ", this.basePointList);
        //                 await this.filterOptionalSelectList(this.pointList);
        //             }
        //             // if(this.selectOptions.thirdOption) {
        //             // } else return;
        //         } catch (e) {
        //             console.error(e);
        //         }
        //     }
        //     else {
        //         try {
        //             if (this.selectOptions.thirdOption) {
        //                 searchParams.equipIdx = this.selectOptions.thirdOption;
        //                 console.log("searchType equip searchParams : ", searchParams);
        //                 let result = await backEndApi.points.getPointInfoByEquip(searchParams);
        //                 if (result.status == 200) {
        //                     // if (result.data.length > 0) this.pointList = result.data;
        //                     // else this.alertWarning("검색 조건과 일치하는 내용이 없습니다. 조회 조건을 확인해주세요.")
        //                     this.pointList = result.data.data;
        //                     this.isLoading = false;
        //                     this.basePointList = [...this.pointList]
        //                     console.log("searchType equip pointList : ", this.pointList);
        //                     console.log("searchType device basePointList : ", this.basePointList);
        //                     await this.filterOptionalSelectList(this.pointList);
        //                 }
        //             } else return;
        //         } catch (e) {
        //             console.error(e);
        //         }
        //     }
        // },

        /** table row format */
        setColumns() {
            this.vgtColumns = this.$store.state.commonCodes.pointInfoField.map(item => {
                let column = {
                    label: this.$t(item.text),
                    field: item.value,
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                }
                if (item.value === "pointAddress") column.compositionFields = ['ptAddr', 'ptName'];
                return column
            })
            this.columns = [...this.vgtColumns]; // 기준 columns
            console.log("Search component vgtColumns : ", this.vgtColumns)
            console.log("Search component columns : ", this.columns)
            this.filterColumns();
        },
        filterColumns() {
            const activeFields = this.pointListOptions.filter(item => item.checked).map(item => item.key);
            console.log(activeFields);
            const filteredColumns = this.columns.filter(column => activeFields.includes(column.field))
            console.log(filteredColumns);
            this.vgtColumns = filteredColumns;
        },
        async handlePointListOptions() {
            this.filterColumns();
        },
        transcodeValue(field, row) {
            const that = this;

            const transCode = that.transCodes.find(code => code.field == field);
            if (transCode) {
                const code = transCode.codes.find(code => code.value === row[field]);
                if (code) return code.text;
                else return row[field] ? row[field] : '-';
            } else {
                return row[field] ? row[field] : '-';
            }
        },
        formatValue(field, row) {
            const that = this;

            let formatted = '';

            const compositionColumn = that.vgtColumns.find(col => col.field === field && col.compositionFields);
            if (compositionColumn) {
                compositionColumn.compositionFields.forEach((subField, index) => {
                    if (!index) {
                        formatted += `<p class="mb-1" style="color: #000; font-weight: 800;">${row[subField] ? row[subField] : '-'} </p>`;
                    } else {
                        formatted += `<p class="m-0" style="color: #777;">(${row[subField] ? row[subField] : '-'})</p>`;
                    }
                })
            } else {
                if (Array.isArray(row[field])) {
                    formatted = row[field].length;
                } else {
                    formatted = this.transcodeValue(field, row);
                }
            }
            return formatted;
        },
        rowStyleClassFn(row) {
            if (row.originalIndex === this.clickedRow?.originalIndex) {
                return 'selectedRow';
            }
            return 'VGT-row';
        },
        async handleRowClick({row, event}) {
            console.log('handleRowClick: ', row, event);
            const checkClick = event.target.closest('.vgt-checkbox-col') ? true : false;
            if(checkClick) return;

            this.clickedRow = row;

            // 선택된 ptIdx도 화면복원시 참조하기 위해 Store에 저장한다.
            const searchOptions = this.$store.state.searchOptions;
            searchOptions.pointInfo.clickedRowId = this.clickedRow?.ptIdx;

            await this.$store.commit("SET_SEARCH_OPTIONS", searchOptions);

            await this.$refs.pointDetailModal.show();
            // console.log('SET_SEARCH_OPTIONS.', searchOptions);
        },

        async handleClosed(ptIdx) {
            console.log('PointMgmt handleClosed() is called. ', ptIdx);
            this.$refs.pointDetailModal.hide();
        },        
        async handleDataUpdated(data) {
            console.log('PointMgmt handleDataUpdated is called. ', data);
            await this.$refs.pointDetailModal.hide();
            await this.searchPointList();
        },

        // searchType 선택
        selectSearchType(type) {
            this.initSelectOptions();
            this.initOptionalSelect();
            this.initOptionalSelectList();
            this.isLoading = false;
            this.searchList = null;
            this.pointList = null;
            if (type) this.searchType = type;
        },

        // 조회한 관제점 선택
        selectPointInfo() {
            let selectedRow = this.$refs['list-table'].selectedRows;

            if (this.mode == 'single') {
                if (selectedRow.length > 1) {
                    this.alertNoti("하나의 관제점을 선택해 주세요.")
                    return;
                } else {
                    this.$emit("select-point", selectedRow[0]);
                }
            } else {
                this.$emit("select-point", selectedRow);
            }

            console.log("this.$refs['list-table']", this.$refs['list-table'])
        },

        // list container height
        listContainerHeight() {
            // console.log("Point Search Component Created(search-container) : ", this.$refs['search-container'].clientHeight);
            // console.log("Point Search Component Created(search-header) : ", this.$refs['search-header'].clientHeight);
            // console.log("Point Search Component Created(search-select) : ", this.$refs['search-select'].clientHeight);
            // console.log("Point Search Component Created(search-select-optional) : ", this.$refs['search-select-optional'].clientHeight);
            let containerHeight = this.$refs['search-container'].clientHeight;
            let headerHeight = this.$refs['search-header'].clientHeight;
            let selectHeight = this.$refs['search-select'].clientHeight;

            if (containerHeight) {
                this.listHeight = Math.floor((containerHeight - (headerHeight + selectHeight)) * 0.9) + 'px';
            }
            // let optionalHeight;
            // if (this.openOptional) {
            //     optionalHeight = this.$refs['search-select-optional'].clientHeight
            //     this.listHeight = Math.floor((containerHeight - (headerHeight + selectHeight + optionalHeight)) * 0.9) + 'px';
            // } else {
            // }
        },

        // 관제점 선택 컴포넌트 destroyed
        closeSearchPoint() {
            this.$emit("close")
        },

        // 표시 항목 선택 modal show
        openTableViewSelect() {
            this.$refs.setPointListOptionsModal.showModal();
        },
        closeTableViewSelect() {
            this.$refs.setPointListOptionsModal.closeModal();
        },
        initOptionalSelectList() {
            this.ptTypeList = this.$store.state.commonCodes.ptTypeCode;
            this.unitList = this.$store.state.units;
            this.energyList = this.$store.state.commonCodes.energySourceCode;

            if (this.searchType == 'point' && this.selectOptions.firstOption == 1) {
                this.usageList = this.$store.state.commonCodes.locPointUsage;
            } else if (this.searchType == 'point' && this.selectOptions.firstOption == 0) {
                this.usageList = this.$store.state.commonCodes.phscPointUsage;
            } else {
                this.usageList = this.$store.state.commonCodes.itemUseageCode;
            }

            this.zoneList = this.$store.state.zoneInfo;
        },
        filterOptionalSelectList(data) {
            // 집계유형 선택목록 재생성
            let ptTypeCodeUsed = [...new Set(data.map(item => item.ptTypeCode))];
            const ptTypeCodes = this.$store.state.commonCodes.ptTypeCode || [];
            this.ptTypeList = ptTypeCodeUsed.map(item => ptTypeCodes.find(code => code.value == item)).filter(item => item != undefined);
            console.log('filterOptionalSelectList ptTypeList:', this.ptTypeList);
            // console.log('filterOptionalSelectList ptTypeCode:', ptTypeCode);
            // console.log('filterOptionalSelectList ptTypeList:', this.$store.state.commonCodes.ptTypeCode);

            // 단위 선택목록 재생성
            let unitUsed = [...new Set(data.map(item => item.unitSmallCode))];
            const unitCodes = this.$store.state.units || [];
            this.unitList = unitUsed.map(item => unitCodes.find(code => code.value == item)).filter(item => item != undefined);
            console.log('filterOptionalSelectList unitList:', this.unitList);
            // console.log('filterOptionalSelectList unitList:', this.$store.state.units);
            // console.log('filterOptionalSelectList units:', units);

            // 에너지원 선택목록 재생성
            let energySourceUsed = [...new Set(data.map(item => item.energySourceCode))];
            const energySourceCodes = this.$store.state.commonCodes.energySourceCode || [];
            this.energyList = energySourceUsed.map(item => energySourceCodes.find(code => code.value == item)).filter(item => item != undefined);
            console.log('filterOptionalSelectList energyList:', this.energyList);

            if (this.searchType !== 'point' || this.selectOptions.thirdOption === null) {
                // 사용용도 선택목록 재생성
                // 관제점유형(논리,물리)에 따라 사용하는 코드 체계가 다름
                // locPointUsage코드(논리)와 phscPointUsage코드(물리)를 병합하여 처리함.
                const locPointUsage = this.$store.state.commonCodes.locPointUsage;
                const phscPointUsage = this.$store.state.commonCodes.phscPointUsage;
                const mergedUsageCodes = [...locPointUsage, ...phscPointUsage];
    
                // value를 키값으로 선택하여 중복을 제거하기 위해 Map에 저장
                const usageMap = new Map();
                mergedUsageCodes.forEach(code => usageMap.set(code.value, code));
    
                let pointUsageUsed = [...new Set(data.map(item => item.itemUseageCode))];
                const itemUseageCodes = Array.from(usageMap.values()).filter(item => item != undefined);
                this.usageList = pointUsageUsed.map(item => itemUseageCodes.find(code => code.value == item)).filter(item => item != undefined);
                console.log('filterOptionalSelectList usageList:', this.usageList);
            }

            // 구역정보 선택목록 재생성
            // codes: this.$store.state.zoneInfo,
            let applyZoneUsed = [...new Set(data.map(item => item.applyZoneIdx))];
            const zoneCodes = this.$store.state.zoneInfo || [];
            this.zoneList = applyZoneUsed.map(item => zoneCodes.find(code => code.value == item)).filter(item => item != undefined);
            console.log('filterOptionalSelectList zoneList:', this.zoneList);
        },
        applyFilter() {
            // API 조회 결과를 대상으로 filter를 적용.
            let filteredList = this.basePointList ?? [];

            // 확장검색의 각 조건들을 중첩하여 적용.
            if (this.optional.ptTypeCode) filteredList = filteredList.filter(item => item.ptTypeCode == this.optional.ptTypeCode);
            if (this.optional.unitSmallCode) filteredList = filteredList.filter(item => item.unitSmallCode == this.optional.unitSmallCode);
            if (this.optional.energySourceCode) filteredList = filteredList.filter(item => item.energySourceCode == this.optional.energySourceCode);
            if (this.optional.itemUseageCode) filteredList = filteredList.filter(item => item.itemUseageCode == this.optional.itemUseageCode);
            if (this.optional.applyZoneIdx) filteredList = filteredList.filter(item => item.applyZoneIdx == this.optional.applyZoneIdx);
            if (this.optional.filterText) filteredList = filteredList.filter(item => {
                let pointText = `${item.ptAddr} ${item.ptName}`.toUpperCase();
                return pointText.includes(this.optional.filterText.toUpperCase());
            })

            // 필터 결과 적용
            this.pointList = filteredList;
        }
    },
}

</script>

<style scoped>
.btn {
    border-color: #e0e0e0;
}

/* detail area styles */
.detail-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.detail-notification-text {
    font-size: 15px;
    font-weight: bold;
}

.detail-container {
    height: 100%;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 10px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    font-size: 12px;
}

.detail-placeholder {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-header {
    width: 100%;

    margin-bottom: 0px !important;
    padding: 8px 16px 8px 16px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 6px 6px 0px 0px;
    background-color: #f1f1f1;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
}

.detail-header .detail-title {
    width: 100%;
    padding: 0 !important;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.detail-header .detail-title .title {
    margin-bottom: 4px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.detail-content {
    height: Calc(100% - 50px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 2px;
    padding: 20px;
    overflow: hidden;
    background-color: white;
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
}

.detail-itemview {
    /* height: 200px; */
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    /* border: solid red 1px; */
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 1rem; */
}

.detail-control {
    margin-bottom: 8px !important;
    display: flex;
    justify-content: flex-end;
}

.detail-menu {
    font-size: 0.8rem;
    font-weight: bold;
}

.detail-bottom {
    margin-top: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40 px;
}

.detail-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 4px;
}

.detail-btn {
    /* height: 50px; */
    flex: 1;
    margin: 0px 4px 0px 4px;
}

.detail-bottom-info {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 8px 4px 8px;
}


.detail-placeholder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: solid #eee 1px;
    ;
    /* border-radius: 10px; */
    /* border: solid #eee 1px; */
}

.form-group {
    margin-bottom: 0.4rem;
}

.flex-shrink-0 {
    flex-shrink: 0;
    /* label의 크기를 콘텐츠에 맞게 조정 */
}

.flex-grow-1 {
    flex-grow: 1;
    /* select 요소가 남은 공간을 차지하도록 설정 */
}

.ms-2 {
    margin-left: 0.5rem;
    /* select 요소와 label 사이의 여백 */
}

.form-label {
    margin-bottom: 0;
}

.form-control {
    flex-grow: 1;
}



.d-flex {
    display: flex;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.flex-grow-1 {
    flex-grow: 1;
}

.setting-icon {
    color: #555;
    background-color: #fff;
    border: solid #bbb 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.setting-icon:hover {
    background-color: #c7c7c7;
}

.setting-icon.active {
    color: #fff;
    background-color: #555;
}

.table-view-container {
    padding: 1rem;
    border-radius: 10px;
    border: solid #eee 1px;
}
</style>